import React from "react";
import { Card, Heading, Box, Link, Flex, Button, Paragraph } from "theme-ui";
import { Icon } from "../Icon";

export const KyberFarm: React.FC = props => {
  return (
    <Card>
      <Heading>
        xUSD-USDC KLP Farm
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <Paragraph>You can farm XDO and KNC by staking your Kyber xUSD-USDC LP tokens.</Paragraph>
        <Paragraph sx={{ mt: 2 }}>
          You can obtain xUSD-USDC LP tokens by adding liquidity to the{" "}
          <Link href={`https://dmm.exchange/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x3a3e7650f8b9f667da98f236010fbf44ee4b2975/0x2616f7285bdcb9c3d0422745b1c8a5751e0ea204`} target="_blank">
            xUSD-USDC pool on Kyber. <Icon name="external-link-alt" size="xs" />
          </Link> and stake your xUSD-USDC KLP tokens to the XDO farming pool on Kyber.
        </Paragraph>
        
        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
          </Flex>
          <Button onClick={() => {
            window.location.href =
              "https://dmm.exchange/#/farms";
          }}>Stake</Button>
        </Flex>
      </Box>
    </Card>
  );
};
