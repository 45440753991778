import { createContext, useContext } from "react";
import type { USDCFarmView, USDCFarmEvent } from "./transitions";

type UsdcFarmViewContextType = {
  view: USDCFarmView;
  dispatchEvent: (event: USDCFarmEvent) => void;
};

export const UsdcFarmViewContext = createContext<UsdcFarmViewContextType | null>(null);

export const useUsdcFarmView = (): UsdcFarmViewContextType => {
  const context: UsdcFarmViewContextType | null = useContext(UsdcFarmViewContext);

  if (context === null) {
    throw new Error("You must add a <UsdcFarmViewProvider> into the React tree");
  }

  return context;
};
