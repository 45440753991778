import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

const selector = ({
  daiUniTokenBalance,
  daiUniTokenAllowance,
  daiLiquidityMiningStake
}: LiquityStoreState) => ({
  daiUniTokenBalance,
  daiUniTokenAllowance,
  daiLiquidityMiningStake
});

type FarmStakeValidation = {
  isValid: boolean;
  hasApproved: boolean;
  hasEnoughUniToken: boolean;
  isWithdrawing: boolean;
  amountChanged: Decimal;
  maximumStake: Decimal;
  hasSetMaximumStake: boolean;
};

export const useValidationState = (amount: Decimal): FarmStakeValidation => {
  const { daiUniTokenBalance, daiUniTokenAllowance, daiLiquidityMiningStake } = useLiquitySelector(selector);
  const isWithdrawing = daiLiquidityMiningStake.gt(amount);
  const amountChanged = isWithdrawing
    ? daiLiquidityMiningStake.sub(amount)
    : Decimal.from(amount).sub(daiLiquidityMiningStake);
  const maximumStake = daiLiquidityMiningStake.add(daiUniTokenBalance);
  const hasSetMaximumStake = amount.eq(maximumStake);

  if (isWithdrawing) {
    return {
      isValid: true,
      hasApproved: true,
      hasEnoughUniToken: true,
      isWithdrawing,
      amountChanged,
      maximumStake,
      hasSetMaximumStake
    };
  }

  const hasApproved = !daiUniTokenAllowance.isZero && daiUniTokenAllowance.gte(amountChanged);
  const hasEnoughUniToken = !daiUniTokenBalance.isZero && daiUniTokenBalance.gte(amountChanged);

  return {
    isValid: hasApproved && hasEnoughUniToken,
    hasApproved,
    hasEnoughUniToken,
    isWithdrawing,
    amountChanged,
    maximumStake,
    hasSetMaximumStake
  };
};
