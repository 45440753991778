import React, { useState, useCallback, useEffect, useRef } from "react";
import { LiquityStoreState, Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { DaiFarmViewContext } from "./FarmViewContext";
import { daiTransitions } from "./transitions";
import type { DAIFarmView, DAIFarmEvent } from "./transitions";

const transition = (view: DAIFarmView, event: DAIFarmEvent): DAIFarmView => {
  const nextView = daiTransitions[view][event] ?? view;
  return nextView;
};

const getInitialView = (
  daiLiquidityMiningStake: Decimal,
  remainingDAILiquidityMiningLQTYReward: Decimal,
  daiLiquidityMiningLQTYReward: Decimal
): DAIFarmView => {
  if (remainingDAILiquidityMiningLQTYReward.isZero) return "DISABLED";
  if (daiLiquidityMiningStake.isZero && daiLiquidityMiningLQTYReward.isZero) return "INACTIVE";
  return "ACTIVE";
};

const selector = ({
  daiLiquidityMiningStake,
  remainingDAILiquidityMiningLQTYReward,
  daiLiquidityMiningLQTYReward
}: LiquityStoreState) => ({
  daiLiquidityMiningStake,
  remainingDAILiquidityMiningLQTYReward,
  daiLiquidityMiningLQTYReward
});

export const DaiFarmViewProvider: React.FC = props => {
  const { children } = props;
  const {
    daiLiquidityMiningStake,
    remainingDAILiquidityMiningLQTYReward,
    daiLiquidityMiningLQTYReward
  } = useLiquitySelector(selector);

  const [view, setView] = useState<DAIFarmView>(
    getInitialView(
      daiLiquidityMiningStake,
      remainingDAILiquidityMiningLQTYReward,
      daiLiquidityMiningLQTYReward
    )
  );
  const viewRef = useRef<DAIFarmView>(view);

  const dispatchEvent = useCallback((event: DAIFarmEvent) => {
    const nextView = transition(viewRef.current, event);

    console.log(
      "dispatchEvent() [current-view, event, next-view]",
      viewRef.current,
      event,
      nextView
    );
    setView(nextView);
  }, []);

  useEffect(() => {
    viewRef.current = view;
  }, [view]);

  useEffect(() => {
    if (daiLiquidityMiningStake.isZero && daiLiquidityMiningLQTYReward.isZero) {
      dispatchEvent("UNSTAKE_AND_CLAIM_CONFIRMED");
    } else if (daiLiquidityMiningStake.isZero && !daiLiquidityMiningLQTYReward.isZero) {
      dispatchEvent("UNSTAKE_CONFIRMED");
    }
  }, [daiLiquidityMiningStake.isZero, daiLiquidityMiningLQTYReward.isZero, dispatchEvent]);

  const provider = {
    view,
    dispatchEvent
  };

  return <DaiFarmViewContext.Provider value={provider}>{children}</DaiFarmViewContext.Provider>;
};
