export async function FetchIS3USDLPAPY() {
  var maxRery = 3;
  var apy: Number = 0;
  var url =  `https://api.thegraph.com/subgraphs/name/gysr-io/gysr-polygon`;  
  console.log(`graph api: ${url}`);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
        "query": "{\n pool(id:\"0xdec7670a2cefc1828da009057c509d77b4a6e76f\") {\n apr\n }\n}",
        "variables": null
      })
  };
  while (maxRery > 0) {
      try{
        const res = await fetch(url, requestOptions);
        const resJson = await res.json();
        const data = resJson.data;
    
        if (!data) {
          await delay(1000);
          maxRery = maxRery - 1;
          continue 
        } else {
          apy = Math.round(parseFloat(data.pool.apr) * 100) /100;
          return { apy };
        }
      }catch(err){
        console.log(`Error occurred while getting apy from graph api: ${url}`);
        apy = 0;
        return {apy};
      }
  }
  return Promise.reject("The graph doesn't have the required data to calculate APY");
} 

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}