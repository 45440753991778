import React, { useEffect, useState } from "react";
import { Card, Heading, Box, Link, Flex, Button, Paragraph, Text } from "theme-ui";
import { Icon } from "../Icon";
import { Badge } from "../Badge";
//import { InfoIcon } from "../InfoIcon";
import { FetchIS3USDLPAPY} from "./FetchIS3USDLPAPY";
export const IronFarm: React.FC = props => {
  const [iS3USDApy, setIS3USDApy] = useState<Number>(0);
  useEffect(() => {
    const fetchData = async () => {
      const {apy} = await FetchIS3USDLPAPY();
      setIS3USDApy(apy);
    };
    fetchData();
  }, []);
  return (
    <Card>
      <Heading>
        xUSD-IS3USD LP Farm
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <Paragraph>You can farm XDO by staking your IronSwap xUSD-IS3USD LP tokens.</Paragraph>
        <Paragraph sx={{ mt: 2 }}>
          You can obtain xUSD-IS3USD LP tokens by adding liquidity to the{" "}
          <Link href={`https://app.iron.finance/swap/pools/isxusd/deposit`} target="_blank">
            xUSD-IS3USD pool on IronSwap. <Icon name="external-link-alt" size="xs" />
          </Link> and stake your xUSD-IS3USD LP tokens to the XDO farming pool on GYSR.
        </Paragraph>
        
        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
          <Badge>
            <Text>
                APY {iS3USDApy}%
            </Text>
          </Badge>
          </Flex>
          <Button onClick={() => {
            window.open(
              "https://docs.xdollar.fi/tutorial/ironswap-liquidity-mining-on-xdollar",
              "_blank");
          }}>Tutorial</Button>
          <Button onClick={() => {
            window.open(
              "https://app.gysr.io/pool/0xdec7670a2cefc1828da009057c509d77b4a6e76f",
              "_blank");
          }}>Stake</Button>
        </Flex>
      </Box>
    </Card>
  );
};
