import React, { useEffect, useState } from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoIcon } from "../InfoIcon";
import { Badge } from "../Badge";
import { fetchLqtyPrice } from "./context/fetchLqtyPrice";


const selector = ({ lusdInStabilityPool, remainingStabilityPoolLQTYReward }: LiquityStoreState) => ({
  lusdInStabilityPool,
  remainingStabilityPoolLQTYReward
});

export const Yield: React.FC = () => {
  const { lusdInStabilityPool, remainingStabilityPoolLQTYReward } = useLiquitySelector(selector);

  const [xdoPrice, setLqtyPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue = remainingStabilityPoolLQTYReward.isZero || lusdInStabilityPool.isZero;
  const XDOTokenAddress = "0x3dc7b06dd0b1f08ef9acbbd2564f8605b4868eea"; 
  const USDCTokenAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"; //TODO: meed to have it generated in config
  const XDOTokenDecimals = 18;
  useEffect(() => {
    (async () => {
      try {
        const { XDOPriceUSD } = await fetchLqtyPrice(XDOTokenAddress, USDCTokenAddress, XDOTokenDecimals);
        setLqtyPrice(XDOPriceUSD);

      } catch (error) {
        console.error(error);
      }
    })();
  }, [XDOTokenAddress, USDCTokenAddress, XDOTokenDecimals]);

  if (hasZeroValue || xdoPrice === undefined) return null;

  const yearlyHalvingSchedule = 0.5; // 50% see LQTY distribution schedule for more info
  const remainingLqtyOneYear = remainingStabilityPoolLQTYReward.mul(yearlyHalvingSchedule);
  const remainingLqtyOneYearInUSD = remainingLqtyOneYear.mul(xdoPrice);
  const aprPercentage = remainingLqtyOneYearInUSD.div(lusdInStabilityPool).mul(100);
  const remainingLqtyInUSD = remainingStabilityPoolLQTYReward.mul(xdoPrice);

  if (aprPercentage.isZero) return null;

  return (
    <Badge>
      <Text>XDO APR {aprPercentage.toString(2)}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" sx={{ width: ["220px", "518px"] }}>
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the XDO return on the xUSD
              deposited to the Stability Pool over the next year, not including your MATIC gains from
              liquidations.
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace", mt: 2 }}>
              (($XDO_REWARDS * YEARLY_DISTRIBUTION%) / DEPOSITED_xUSD) * 100 ={" "}
              <Text sx={{ fontWeight: "bold" }}> APR</Text>
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              ($
              {remainingLqtyInUSD.shorten()} * 50% / ${lusdInStabilityPool.shorten()}) * 100 =
              <Text sx={{ fontWeight: "bold" }}> {aprPercentage.toString(2)}%</Text>
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
