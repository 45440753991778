import { Decimal } from "@liquity/lib-base";

export async function fetchLqtyPrice(fromTokenAddress: string, toTokenAddress:string, fromTokenDecimals: number) {
  var maxRery = 3;
  const fromTokenAmount = Decimal.from(10).pow(fromTokenDecimals);
  var url =  `https://api.1inch.exchange/v3.0/137/quote?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${fromTokenAmount}`;  
  console.log(`1inch api: ${url}`);

  while (maxRery > 0) {
    const res = await fetch(url);
    const quote = await res.json();

    var priceraw = quote.toTokenAmount
    var decimals = quote.toToken.decimals;
    if (!priceraw || !decimals) {
      await delay(1000);
      maxRery = maxRery - 1;
      continue 
    } else {
      const XDOPriceUSD = Decimal.from(priceraw).div(Decimal.from(10).pow(quote.toToken.decimals)); 
      return { XDOPriceUSD };
    }
  }
  return Promise.reject("1inch doesn't have the required data to calculate yield");
} 

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}