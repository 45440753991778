import React from "react";
import { Card, Heading, Box, Link, Flex, Button, Paragraph } from "theme-ui";
import { Icon } from "../Icon";

export const GysrKyberFarm: React.FC = props => {
  return (
    <Card>
      <Heading>
        xUSD-DAI KLP Farm
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <Paragraph>You can farm XDO by staking your Kyber xUSD-DAI LP tokens.</Paragraph>
        <Paragraph sx={{ mt: 2 }}>
          You can obtain xUSD-DAI LP tokens by adding liquidity to the{" "}
          <Link href={`https://dmm.exchange/#/add/0x3A3e7650f8B9f667dA98F236010fBf44Ee4B2975/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0xAAAe5aabDB7db627c58dfEeBa27bA2933A39c592`} target="_blank">
            xUSD-DAI pool on Kyber. <Icon name="external-link-alt" size="xs" />
          </Link> and stake your xUSD-DAI KLP tokens to the XDO farming pool on GYSR.
        </Paragraph>
        
        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
          </Flex>
          <Button onClick={() => {
            window.open(
              "https://app.gysr.io/pool/0xfae7272e953d889169f13d8fdc0e730b6c61429d",
              "_blank");
          }}>Stake</Button>
        </Flex>
      </Box>
    </Card>
  );
};
