import { Decimal } from "@liquity/lib-base";

type UniswapResponse = {
  data?: {
    bundle: {
      ethPrice: string;
    } | null;
    pool: {
      token0Price: string;
      reserveUSD: string;
      totalSupply: string;
    } | null;
    token: {
      derivedETH: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

type UniswapPairResponse = {
  data?: {
    pair: {
      token0Price: string;
      reserveUSD: string;
      totalSupply: string;
    } | null;
  };
  errors?: Array<{ message: string }>;
};

const MATICTokenAddress = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
const sushiXDOMaticAddress = "0xaa5843865437ed4448bf95904f114264f0e4b113";

const uniswapQuery = (MATICTokenAddress: string, sushixUSDMaticLpAddress: string) => `{
  token(id: "${MATICTokenAddress.toLowerCase()}") {
    derivedETH
  },
  bundle(id: 1) {
    ethPrice
  },
  pool(id: "${sushixUSDMaticLpAddress.toLowerCase()}") {
    token0Price
    totalSupply
    reserveUSD
  }
}`;

const uniswapPairQuery = (xDOMaticLpAddress: string) => `{
  pair(id: "${xDOMaticLpAddress.toLowerCase()}") {
    token0Price
    totalSupply
    reserveUSD
  }
}`;

export async function fetchPrices(uniTokenAddress: string) {
  // First figuring out the XDO price.
  const xdoResponse = await window.fetch("https://api.thegraph.com/subgraphs/name/sushiswap/matic-exchange", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapPairQuery(sushiXDOMaticAddress),
      variables: null
    })
  });

  const xUSDResponse = await window.fetch("https://api.thegraph.com/subgraphs/name/piavgh/dmm-exchange-matic", {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: uniswapQuery(MATICTokenAddress, uniTokenAddress),
      variables: null
    })
  });

  if (!xdoResponse.ok || !xUSDResponse.ok) {
    return Promise.reject("Network error connecting to Sushiswap subgraph");
  }

  const xdoResponseJSON: UniswapPairResponse = await xdoResponse.json();
  const xUSDResponseJSON: UniswapResponse = await xUSDResponse.json();

  if (xdoResponseJSON.errors) {
    return Promise.reject(xdoResponseJSON.errors);
  }

  if (xUSDResponseJSON.errors) {
    return Promise.reject(xUSDResponseJSON.errors);
  }

  if (
    typeof xdoResponseJSON.data?.pair?.reserveUSD === "string" &&
    typeof xdoResponseJSON.data?.pair?.totalSupply === "string" &&
    typeof xdoResponseJSON.data?.pair?.token0Price === "string" &&
    typeof xUSDResponseJSON.data?.token?.derivedETH === "string" &&
    typeof xUSDResponseJSON.data?.pool?.reserveUSD === "string" &&
    typeof xUSDResponseJSON.data?.pool?.totalSupply === "string" &&
    typeof xUSDResponseJSON.data?.bundle?.ethPrice === "string" &&
    typeof xUSDResponseJSON.data?.pool?.token0Price === "string"
  ) {
    const ethPriceUSD = Decimal.from(xUSDResponseJSON.data.bundle.ethPrice);
    const MaticPriceUSD = Decimal.from(xUSDResponseJSON.data.token.derivedETH).mul(ethPriceUSD);
    const xdoPriceUSD = Decimal.from(xdoResponseJSON.data.pair.token0Price).mul(MaticPriceUSD);
    const KyberLPPriceUSD = Decimal.from(xUSDResponseJSON.data.pool.reserveUSD).div(
      Decimal.from(xUSDResponseJSON.data.pool.totalSupply)
    );

    // console.log(`ethPriceUSD : ${ethPriceUSD}`);
    // console.log(`MaticPriceUSD : ${MaticPriceUSD}`);
    // console.log(`xdoPriceUSD : ${xdoPriceUSD}`);
    // console.log(`uniLpPriceUSD : ${KyberLPPriceUSD}`);

    return { xdoPriceUSD, KyberLPPriceUSD };
  }

  return Promise.reject("Kyber doesn't have the required data to calculate yield");
}
