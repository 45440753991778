import React, { useState, useCallback, useEffect, useRef } from "react";
import { LiquityStoreState, Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { UsdcFarmViewContext } from "./FarmViewContext";
import { usdcTransitions } from "./transitions";
import type { USDCFarmView, USDCFarmEvent } from "./transitions";

const transition = (view: USDCFarmView, event: USDCFarmEvent): USDCFarmView => {
  const nextView = usdcTransitions[view][event] ?? view;
  return nextView;
};

const getInitialView = (
  usdcLiquidityMiningStake: Decimal,
  remainingUSDCLiquidityMiningLQTYReward: Decimal,
  usdcLiquidityMiningLQTYReward: Decimal
): USDCFarmView => {
  if (remainingUSDCLiquidityMiningLQTYReward.isZero) return "DISABLED";
  if (usdcLiquidityMiningStake.isZero && usdcLiquidityMiningLQTYReward.isZero) return "INACTIVE";
  return "ACTIVE";
};

const selector = ({
  usdcLiquidityMiningStake,
  remainingUSDCLiquidityMiningLQTYReward,
  usdcLiquidityMiningLQTYReward
}: LiquityStoreState) => ({
  usdcLiquidityMiningStake,
  remainingUSDCLiquidityMiningLQTYReward,
  usdcLiquidityMiningLQTYReward
});

export const UsdcFarmViewProvider: React.FC = props => {
  const { children } = props;
  const {
    usdcLiquidityMiningStake,
    remainingUSDCLiquidityMiningLQTYReward,
    usdcLiquidityMiningLQTYReward
  } = useLiquitySelector(selector);

  const [view, setView] = useState<USDCFarmView>(
    getInitialView(
      usdcLiquidityMiningStake,
      remainingUSDCLiquidityMiningLQTYReward,
      usdcLiquidityMiningLQTYReward
    )
  );
  const viewRef = useRef<USDCFarmView>(view);

  const dispatchEvent = useCallback((event: USDCFarmEvent) => {
    const nextView = transition(viewRef.current, event);

    console.log(
      "dispatchEvent() [current-view, event, next-view]",
      viewRef.current,
      event,
      nextView
    );
    setView(nextView);
  }, []);

  useEffect(() => {
    viewRef.current = view;
  }, [view]);

  useEffect(() => {
    if (usdcLiquidityMiningStake.isZero && usdcLiquidityMiningLQTYReward.isZero) {
      dispatchEvent("UNSTAKE_AND_CLAIM_CONFIRMED");
    } else if (usdcLiquidityMiningStake.isZero && !usdcLiquidityMiningLQTYReward.isZero) {
      dispatchEvent("UNSTAKE_CONFIRMED");
    }
  }, [usdcLiquidityMiningStake.isZero, usdcLiquidityMiningLQTYReward.isZero, dispatchEvent]);

  const provider = {
    view,
    dispatchEvent
  };

  return <UsdcFarmViewContext.Provider value={provider}>{children}</UsdcFarmViewContext.Provider>;
};
