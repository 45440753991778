import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";
import { DAILP, GT } from "../../../../strings";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { Icon } from "../../../Icon";
import { LoadingOverlay } from "../../../LoadingOverlay";
import { useMyTransactionState } from "../../../Transaction";
import { DisabledEditableRow, StaticRow } from "../../../Trove/Editor";
import { useDaiFarmView } from "../../context/FarmViewContext";
import { RemainingLQTY } from "../RemainingLQTY";
import { ClaimReward } from "./ClaimReward";
import { UnstakeAndClaim } from "../UnstakeAndClaim";
import { Yield } from "../Yield";

const selector = ({
  daiLiquidityMiningStake,
  daiLiquidityMiningLQTYReward,
  totalStakedDAIUniTokens
}: LiquityStoreState) => ({
  daiLiquidityMiningStake,
  daiLiquidityMiningLQTYReward,
  totalStakedDAIUniTokens
});
const transactionId = /farmdai-/i;

export const Active: React.FC = () => {
  const { dispatchEvent } = useDaiFarmView();
  const {
    daiLiquidityMiningStake,
    daiLiquidityMiningLQTYReward,
    totalStakedDAIUniTokens
  } = useLiquitySelector(selector);

  const handleAdjustPressed = useCallback(() => {
    dispatchEvent("ADJUST_PRESSED");
  }, [dispatchEvent]);

  const transactionState = useMyTransactionState(transactionId);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const poolShare = daiLiquidityMiningStake.mulDiv(100, totalStakedDAIUniTokens);
  const hasStakeAndRewards = !daiLiquidityMiningStake.isZero && !daiLiquidityMiningLQTYReward.isZero;

  return (
    <Card>
      <Heading>
        xUSD-DAI KLP Farm
        {!isTransactionPending && (
          <Flex sx={{ justifyContent: "flex-end" }}>
            <RemainingLQTY />
          </Flex>
        )}
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <Box>
          <DisabledEditableRow
            label="Stake"
            inputId="farmdai-stake"
            amount={daiLiquidityMiningStake.prettify(4)}
            unit={DAILP}
          />
          {poolShare.infinite ? (
            <StaticRow label="Pool share" inputId="daifarm-share" amount="N/A" />
          ) : (
            <StaticRow
              label="Pool share"
              inputId="farmdai-share"
              amount={poolShare.prettify(4)}
              unit={"%"}
            />
          )}
          <Flex sx={{ alignItems: "center" }}>
            <StaticRow
              label="Reward"
              inputId="farmdai-reward"
              amount={daiLiquidityMiningLQTYReward.prettify(4)}
              color={daiLiquidityMiningLQTYReward.nonZero && "success"}
              unit={GT}
            />
            <Flex sx={{ justifyContent: "flex-end", flex: 1 }}>
              <Yield />
            </Flex>
          </Flex>
        </Box>

        <Flex variant="layout.actions">
          <Button
            variant={!daiLiquidityMiningLQTYReward.isZero ? "outline" : "primary"}
            onClick={handleAdjustPressed}
          >
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>
          {!daiLiquidityMiningLQTYReward.isZero && <ClaimReward />}
        </Flex>
        <Flex>{hasStakeAndRewards && <UnstakeAndClaim />}</Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
