import { Container } from "theme-ui";
import { SystemStats } from "../components/SystemStats";
import { Farm as FarmPanel } from "../components/Farm/Farm";
import { UsdcFarm as UsdcFarmPanel } from "../components/FarmUsdc/Farm";
import { DaiFarm as DaiFarmPanel } from "../components/FarmDai/Farm";
import { IronFarm as IronFarmPanel } from "../components/FarmIron/Farm";
import { GysrKyberFarm as GysrKyberFarmPanel } from "../components/FarmGysrKyber/Farm";
import { KyberFarm as KyberFarmPanel } from "../components/FarmKyber/Farm";

export const Farm: React.FC = () => (
  <Container variant="columns" sx={{ justifyContent: "flex-start" }}>
    <Container variant="left">
      <KyberFarmPanel />
      <GysrKyberFarmPanel />
      <IronFarmPanel />
      <FarmPanel />
      <UsdcFarmPanel />
      <DaiFarmPanel />
    </Container>

    <Container variant="right">
      <SystemStats />
    </Container>
  </Container>
);
