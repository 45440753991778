import { createContext, useContext } from "react";
import type { DAIFarmView, DAIFarmEvent } from "./transitions";

type DaiFarmViewContextType = {
  view: DAIFarmView;
  dispatchEvent: (event: DAIFarmEvent) => void;
};

export const DaiFarmViewContext = createContext<DaiFarmViewContextType | null>(null);

export const useDaiFarmView = (): DaiFarmViewContextType => {
  const context: DaiFarmViewContextType | null = useContext(DaiFarmViewContext);

  if (context === null) {
    throw new Error("You must add a <DaiFarmViewProvider> into the React tree");
  }

  return context;
};
