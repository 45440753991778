import { Container } from "theme-ui";
import { Card, Heading, Box, Flex, Button, Paragraph, Link } from "theme-ui";

export const Bridge: React.FC = () => (
  <Container variant="columns" sx={{ justifyContent: "flex-start" }}>
    <Container variant="left">
      <Card>
        <Heading>{"XDO Polygon <> Ethereum Bridge"}</Heading>
        <Box sx={{ p: [2, 3] }}>
          <Paragraph>You can use Anyswap moving XDO between Polygon and Ethereum.</Paragraph>

          <Flex sx={{ justifyContent: "center", mt: 4 }}>
            <Paragraph>
              <Link href="https://docs.xdollar.fi/tutorial/xdo-bridge" target="_blank">
                XDO Bridge Tutorial
              </Link>
            </Paragraph>
          </Flex>

          <Flex sx={{ justifyContent: "center", mt: 4 }}>
            <Button
              variant={"primary"}
              onClick={() => {
                window.location.href =
                  "https://anyswap.exchange/bridge?inputCurrency=0x173fd7434b8b50df08e3298f173487ebdb35fd14&network=eth";
              }}
            >
              Bridge
            </Button>
          </Flex>
        </Box>
      </Card>
    </Container>
  </Container>
);
